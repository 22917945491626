import { useEffect } from 'react';

import { AppBar } from 'design-system';
import { useScroll, useTransform } from 'framer-motion';
import { cn } from 'tailwind-config';

import { TOP_NAV_BAR_HEIGHT } from '@constants';
import { useWindowSize } from '@hooks';

import { GlobalAppBarItem } from './components';
import { useGetAppBarProps } from './hooks';

// framer-motion의 scrollYProgress가 작동하지 않아서 따로 구현함
// https://www.notion.so/framer-motion-scrollYProgress-1-0a943817d4f94c7d89e942a974239b47?pvs=4
// blog 페이지에서는 개발 상태에서 페이지가 정적 생성되지 않아 offsetHeight가 잘못 찍히는 이슈가 있으나, production에서는 정상 작동함
const useScrollYProgress = () => {
  const { height } = useWindowSize({
    isTopNavbarHeight: false,
  });
  // 이걸 scroll 옵션과 상관없이 항상  성능적으로 이상이 있는지는 확인 펼요
  const { scrollY } = useScroll();
  const scrollYProgress = useTransform(
    scrollY,
    [0, typeof window !== 'undefined' ? window.document.body.offsetHeight - height : 0],
    [0, 1],
  );

  return scrollYProgress;
};

/**
 * 전역적으로 사용되는 AppBar 입니다.
 * AppBar : DS의 AppBar. 디자인 요소만 존재합니다.
 * GlobalAppBar : AppBar를 사용하는 컴포넌트. 디자인 요소와 로직이 존재합니다.
 * LocalAppBar (이름은 각 상황에 맞게 조절) : AppBar를 사용하는 컴포넌트. 디자인 요소와 로직이 존재합니다. 상황에 따라 GlobalAppBar를 사용할 수 없을 경우 LocalAppBar를 정의해서 사용합니다.
 */
export const GlobalAppBar = ({ className }: { className?: string }) => {
  const {
    leftItem,
    type,
    rightItems = [],
    text,
    offNavbar,
    isFixed,
    showProgressOnScroll = false,
    backgroundColor,
    backRedirectUrl,
  } = useGetAppBarProps();

  const scrollYProgress = useScrollYProgress();

  /**
   * custom 한 appbar를 사용하는 경우 ScrollContainer의 height를 auto로 변경해줌.
   */
  useEffect(() => {
    if (offNavbar) {
      const mainEL = document.getElementById('scroll-container');

      if (mainEL) {
        mainEL.style.height = 'auto';
        return () => {
          mainEL.style.height = 'var(--app-content-height)';
        };
      }
    }
  }, [offNavbar]);

  if (offNavbar) return null;

  return (
    <div className={cn('relative', className)}>
      <AppBar
        className={cn(
          (isFixed || backgroundColor === 'transparent') && 'fixed top-0 z-[100] max-w-[498px]',
        )}
        style={{
          backgroundColor,
        }}
        type={type ?? 'title-center'}
        leftItem={<GlobalAppBarItem item={leftItem} backRedirectUrl={backRedirectUrl} />}
        rightItem={rightItems.map((item, index) => (
          <GlobalAppBarItem key={item + index.toString()} item={item} />
        ))}
        text={text}
        progressBarOption={{
          showProgressBar: showProgressOnScroll,
          progressPercentage: scrollYProgress,
        }}
      />
      {isFixed && <div style={{ height: TOP_NAV_BAR_HEIGHT }} />}
    </div>
  );
};
