import { useRouter } from 'next/router';

const prod = process.env.NEXT_PUBLIC_PLATFORM_ENV === 'production';

export const ADSense = () => {
  const router = useRouter();
  return (
    <>
      {/* {!router.pathname.includes('test') && prod && (
        <Script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9018600439614344"
          crossOrigin="anonymous"
        ></Script>
      )} */}
    </>
  );
};

export default ADSense;
