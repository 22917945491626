import { isValidElement, useCallback, useState } from 'react';

import { AppBarItem, NewIcon } from 'design-system';
import { atom, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useLocalStorage } from 'usehooks-ts';

import { Popover } from '@components/Popover';

import { useMounted } from '@hooks';
import { isAppApproaching } from '@utils/Common';

import { BackLink } from './BackLink';

const isAppApproach = isAppApproaching();

export const visitCouponModalAtom = atom(false);

export const GlobalAppBarItem = ({
  item,
  backRedirectUrl,
}: {
  item?: GlobalAppBarItem;
  backRedirectUrl?: string;
}) => {
  const router = useRouter();

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const mounted = useMounted();
  const setVisitCouponModalVisible = useSetAtom(visitCouponModalAtom);

  const [visitCouponExist] = useLocalStorage('visit-coupon-5000', false);

  const renderItem = useCallback(
    (item: GlobalAppBarItem) => {
      switch (item) {
        case 'Back':
          return (
            <BackLink backRedirectUrl={backRedirectUrl}>
              <AppBarItem
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-gray-900" />}
              />
            </BackLink>
          );
        case 'WhiteBack':
          return (
            <BackLink backRedirectUrl={backRedirectUrl}>
              <AppBarItem
                item={<NewIcon icon="arrowline-outlined" size={24} className="fill-new-white" />}
              />
            </BackLink>
          );
        case 'Close':
          return (
            <BackLink backRedirectUrl={backRedirectUrl}>
              <NewIcon icon="x-outlined" size={24} className="fill-new-gray-900" />
            </BackLink>
          );
        case 'ProfileSetting':
          return (
            <AppBarItem
              item={<NewIcon icon="gear-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/profile/setting');
              }}
            />
          );
        case 'ProfileNotification':
          if (!isAppApproach) return null;

          return (
            <AppBarItem
              className="show-on-webview"
              item={<NewIcon icon="bell-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/profile/notification');
              }}
            />
          );
        case 'Coupon':
          // popover의 Hydration error 때문에 다음과 같이 해결
          if (!mounted) return null;

          return (
            <div ref={setContainer} className="z-[1000]">
              <Popover
                container={container}
                align="end"
                content={'안 받으신 쿠폰이 있어요! 🎁'}
                side="bottom"
                color="purple"
                closeButton={false}
                sideOffset={10}
                alignOffset={-16}
                open={visitCouponExist}
              >
                <AppBarItem
                  item={<NewIcon icon="coupon-outlined" size={24} className="fill-new-gray-900" />}
                  onClick={() => {
                    if (visitCouponExist) {
                      setVisitCouponModalVisible(true);
                    } else {
                      router.push('/profile/storage/coupon');
                    }
                  }}
                />
              </Popover>
            </div>
          );
        case 'TextLogo':
          return (
            <AppBarItem
              item={<NewIcon icon="text-logo" width={107} height={32} />}
              onClick={() => {
                router.push('/');
              }}
            />
          );
        case 'Home':
          return (
            <AppBarItem
              item={<NewIcon icon="home-outlined" size={24} className="fill-new-gray-900" />}
              onClick={() => {
                router.push('/');
              }}
            />
          );
        default:
          return <AppBarItem item={<div />} />;
      }
    },
    [router, backRedirectUrl, visitCouponExist, mounted, container],
  );

  if (!item) return <AppBarItem item={<div />} />;

  if (isValidElement(item)) return <AppBarItem item={item} />;

  return <>{renderItem(item)}</>;
};
