import * as RadixPopover from '@radix-ui/react-popover';
import { VariantProps, cva } from 'class-variance-authority';
import { NewIcon } from 'design-system';
import { cn } from 'tailwind-config';

const popoverVariants = cva(
  'relative flex items-start gap-4 text-new-Caption1-bold px-12 py-8 rounded-[8px] whitespace-pre-line focus-visible:outline-none',
  {
    variants: {
      color: {
        red: 'bg-DTRed-400 text-white',
        black: 'bg-black/75 text-white',
        green: 'bg-DTGreen-400 text-white',
        purple: 'bg-new-DTPurple-500 text-white',
      },
    },
    defaultVariants: {
      color: 'black',
    },
  },
);

const popoverArrowVariants = cva('-bottom-1', {
  variants: {
    color: {
      red: 'fill-DTRed-400',
      black: 'fill-black/75',
      green: 'fill-DTGreen-400',
      purple: 'fill-new-DTPurple-500',
    },
  },
  defaultVariants: {
    color: 'black',
  },
});

const PopoverArrow = ({
  className,
  color,
}: React.ComponentPropsWithoutRef<'path'> & VariantProps<typeof popoverArrowVariants>) => (
  <div>
    <svg width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className={cn(popoverArrowVariants({ color, className }))}
        d="M8.39728 5.47106C7.62026 6.22979 6.37974 6.22979 5.60273 5.47106L6.39776e-07 0.000187684L14 0.000188828L8.39728 5.47106Z"
      />
    </svg>
  </div>
);

type PopoverProps = {
  content: React.ReactNode;
  children: React.ReactNode;
  closeButton?: boolean;
  triggerClassName?: string;
  container?: RadixPopover.PopoverPortalProps['container'];
} & React.ComponentPropsWithoutRef<typeof RadixPopover.Content> &
  VariantProps<typeof popoverVariants> &
  Pick<React.ComponentPropsWithoutRef<typeof RadixPopover.Root>, 'open' | 'onOpenChange'>;

export function Popover({
  content,
  className,
  color,
  side,
  open,
  onOpenChange,
  children,
  closeButton = true,
  triggerClassName,
  container,
  ...rest
}: PopoverProps) {
  const portalEL = typeof document !== 'undefined' ? document.getElementById('portal') : null;

  return (
    <RadixPopover.Root open={open} onOpenChange={onOpenChange}>
      <RadixPopover.Trigger className={cn(triggerClassName)}>{children}</RadixPopover.Trigger>
      <RadixPopover.Portal container={container ?? portalEL}>
        <RadixPopover.Content
          side={side}
          className={cn(popoverVariants({ color, className }))}
          {...rest}
        >
          {content}
          {closeButton && (
            <RadixPopover.Close className="inline-grid place-items-center">
              <NewIcon icon="x-outlined" size={16} className="fill-new-white" />
            </RadixPopover.Close>
          )}
          <RadixPopover.Arrow asChild>
            <PopoverArrow color={color} className="visible" />
          </RadixPopover.Arrow>
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
