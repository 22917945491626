'use client';

import { ElementRef, forwardRef } from 'react';

import * as RadixToast from '@radix-ui/react-toast';
import { motion } from 'framer-motion';
import { cn } from 'tailwind-config';

import { NewIcon, NewIconType } from '../NewIcon';

export interface ToastProps {
  title: React.ReactNode;
  description?: string;
  icon?: NewIconType;
  yPosition?: number;
  type?: 'single' | 'multiple';
}

const ToastTitle = forwardRef<
  React.ElementRef<typeof RadixToast.Title>,
  React.ComponentPropsWithoutRef<typeof RadixToast.Title>
>(({ className, ...props }, ref) => (
  <RadixToast.Title
    ref={ref}
    className={cn('text-new-Body2-bold w-max whitespace-pre-wrap', className)}
    {...props}
  />
));

const ToastDescription = forwardRef<
  React.ElementRef<typeof RadixToast.Description>,
  React.ComponentPropsWithoutRef<typeof RadixToast.Description>
>(({ className, ...props }, ref) => (
  <RadixToast.Description
    ref={ref}
    className={cn('w-max whitespace-pre-wrap', className)}
    {...props}
  />
));

const DEFAULT_Y_POSITION = 100;

export const Toast = forwardRef<
  ElementRef<typeof RadixToast.Root>,
  {
    onClose: () => void;
  } & ToastProps
>(function Toast({ onClose, title, description, icon, yPosition }, forwardedRef) {
  return (
    <RadixToast.Root ref={forwardedRef} asChild forceMount onOpenChange={onClose} duration={2500}>
      <motion.li
        layout
        initial={{ y: 0, opacity: 0 }}
        animate={{ y: -(yPosition ?? DEFAULT_Y_POSITION), opacity: 0.75 }}
        exit={{
          opacity: 0,
          // y: 100,
          zIndex: -1,
        }}
        transition={{
          type: 'spring',
          mass: 1,
          damping: 30,
          stiffness: 200,
        }}
        style={{ WebkitTapHighlightColor: 'transparent' }}
        className="gap-6 rounded-[10px] bg-[#242523] px-20 py-12 text-white"
      >
        <div className="flex items-center justify-between">
          {icon && <NewIcon icon={icon} />}
          <ToastTitle>{title}</ToastTitle>
        </div>
        {description && <ToastDescription>{description}</ToastDescription>}
      </motion.li>
    </RadixToast.Root>
  );
});
