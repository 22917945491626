import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cn } from 'tailwind-config/utils/utils';

import { StaticIcon } from '@components/Activation/written-test/components/StaticIcon';

import { PAGE_MAPPING } from '@constants/page-mapping';
import { BOTTOM_NAV_BAR_HEIGHT } from '@constants/style';
import { usePreventBackGestureOnIOS } from '@hooks';
import { isAppApproaching } from '@utils/Common/is-app-approaching';

import { useHandleAppNavigation } from './actions/use-handle-app-navigation';

const ReviewCoachMark = dynamic(() => import('./components').then((m) => m.ReviewCoachMark));
const Breadcrumbs = dynamic(() => import('./components').then((m) => m.Breadcrumbs));

export const BOTTOM_NAV_BAR_ITEMS = [
  {
    icon: <StaticIcon icon="home-outlined" size={24} className="fill-new-gray-500" />,
    activeIcon: <StaticIcon icon="home-filled" size={24} className="fill-new-gray-900" />,
    path: '/',
    text: '홈',
  },
  {
    icon: <StaticIcon icon="compass-outlined" size={24} className="fill-new-gray-500" />,
    activeIcon: <StaticIcon icon="compass-filled" size={24} className="fill-new-gray-900" />,
    path: '/explore?init=true',
    text: '예약',
  },
  {
    icon: <StaticIcon icon="pencil-outlined" size={24} className="fill-new-gray-500" />,
    activeIcon: <StaticIcon icon="pencil-filled" size={24} className="fill-new-gray-900" />,
    path: '/test',
    text: '필기',
  },
  {
    icon: <StaticIcon icon="content-outlined" size={24} className="fill-new-gray-500" />,
    activeIcon: <StaticIcon icon="content-filled" size={24} className="fill-new-gray-900" />,
    path: '/blog',
    text: '블로그',
  },
  {
    icon: <StaticIcon icon="profile-outlined" size={24} className="fill-new-gray-500" />,
    activeIcon: <StaticIcon icon="profile-filled" size={24} className="fill-new-gray-900" />,
    path: '/profile',
    text: '내정보',
  },
];
export const BOTTOM_NAVBAR_ENABLED_PATHS = ['/', '/explore', '/test', '/blog', '/profile'];

const isAppApproach = isAppApproaching() && !window.isBottomNavbarVisible;

export const useLoadingOnPageRoute = () => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsLoading(true));
    router.events.on('routeChangeComplete', () => setIsLoading(false));

    return () => {
      router.events.off('routeChangeStart', () => setIsLoading(true));
      router.events.off('routeChangeComplete', () => setIsLoading(false));
    };
  }, [router]);

  return { isLoading };
};

export const useGetShowBottomNavbar = () => {
  const router = useRouter();
  const currentRoute = router.pathname;
  const showBottomNavbar = BOTTOM_NAVBAR_ENABLED_PATHS.includes(currentRoute);

  return { showBottomNavbar, currentRoute };
};

const RenderedBottomNavbar = () => {
  const { currentRoute } = useGetShowBottomNavbar();

  useHandleAppNavigation();
  usePreventBackGestureOnIOS();

  return (
    <>
      <Breadcrumbs />
      <>
        <div
          style={{
            height: BOTTOM_NAV_BAR_HEIGHT,
            backgroundColor: PAGE_MAPPING[currentRoute as keyof typeof PAGE_MAPPING]
              ? (
                  PAGE_MAPPING[currentRoute as keyof typeof PAGE_MAPPING] as {
                    bgColor?: string;
                  }
                ).bgColor || '#ffffff'
              : '#ffffff',
          }}
        />
        <nav
          id="BottomNavBar"
          className="pt-13 evenly absolute bottom-0 right-1/2 z-[200] flex w-full max-w-[498px] translate-x-1/2 bg-white px-16 pb-10 shadow-[0px_-4px_10px_0px_rgba(0,0,0,0.08)]"
          style={{
            height: BOTTOM_NAV_BAR_HEIGHT,
          }}
        >
          <ReviewCoachMark />
          {BOTTOM_NAV_BAR_ITEMS.map((navbarItem) => {
            const isCurrentRoute =
              currentRoute === '/'
                ? navbarItem.path === '/'
                : navbarItem.path.includes(currentRoute);
            return (
              <Link
                href={navbarItem.path}
                replace
                key={navbarItem.path}
                className={cn(
                  'text-new-gray-600 text-Detail1 flex flex-1 cursor-pointer flex-col items-center gap-3',
                  isCurrentRoute ? 'text-new-gray-900' : '',
                )}
                onClick={(e) => {
                  if (isCurrentRoute) {
                    e.preventDefault();
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }
                }}
              >
                {isCurrentRoute ? navbarItem.activeIcon : navbarItem.icon}
                <span>{navbarItem.text}</span>
              </Link>
            );
          })}
        </nav>
      </>
    </>
  );
};

export const BottomNavbar = ({ className }: { className?: string }) => {
  const { showBottomNavbar } = useGetShowBottomNavbar();

  if (isAppApproach || !showBottomNavbar) return <ReviewCoachMark />;

  return (
    <div className={className}>
      <RenderedBottomNavbar />
    </div>
  );
};
